<template>
	<div class="home">
		<div class="homeHeader">
			<img class="homeHeaderImage" src="../assets/logo-3.png" />
			<span class="homeHeaderName">
				NK检验报告查询
			</span>
		</div>
		<div class="homeMain">
			<input class="homeMainPhone" v-model="phone" type="text" placeholder="请输入手机号码" /> 
			<div class="homeMainCodeBox">
				<input class="homeMainCode" v-model.number="code" type="text" placeholder="请输入验证码" /> 
				<button 
					class="homeMainButton" 
					:disabled='isDisabled' 
					:class="isDisabled ? 'homeMainButtonActive' : ''"
					@click="sendCode">{{buttonText}}</button>
			</div>
			<div class="homeMainTime">
				<div class="homeMainTimeItem">
					<div class="homeMainTimeItemLeft">开始日期</div>
					<div class="homeMainTimeItemRight" @click="goStart">
						<span>{{startTime}}</span>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			<div class="homeMainSearch">
				<button class="homeMainSearchButton" @click="submitFrom">立即查询</button>
			</div>
		</div>
		<van-popup v-model="isShowBottom" position="bottom" :style="{ height: '40%' }">
			<van-datetime-picker
				v-model="currentDateBottom"
				@confirm='changeBottom'
				type="date"
				title="选择年月日"
				:min-date="minDateBottom"
				:max-date="maxDateBottom"
				/>
		</van-popup>
		<!-- 选择日期 -->
		<van-calendar title="只能查询30天内的报告" v-model="isShowTop" :default-date="startTimeString"  @confirm="onConfirm"  :min-date="minDate" :max-date="maxDate"  />
	</div>
</template>

<script>
import { sendCode, phoneLogin , info} from '../https/api'
import moment from 'moment'
export default {
	name: 'Home',
	data(){
		return {
			text: null,
			buttonText: '发送验证码',
			isShowTop: false,
			minDate: new Date(new Date().getTime() - (86400000 * 29)),
			maxDate:new Date(),
			currentDate: new Date(2021, 0, 17),
			startTime:  moment(new Date().getTime() - (86400000 * 3) ).format('YYYY-MM-DD'),
			startTimeString: new Date(),
			endTime: moment(new Date()).format('YYYY-MM-DD'),
			isShowBottom: false,
			minDateBottom: new Date(2020, 1, 1),
			maxDateBottom: new Date(),
			currentDateBottom: new Date(2021, 0, 17),
			phone: null,
			isDisabled: false,
			isSendTime: null,
			isPhone: null,
			code: null
		}
	},
	created(){
		// this.getUserInfo()
	},
	methods:{
		moment,
		goStart(){
			this.isShowTop = true
		},
		onConfirm(date) {
			this.isShowTop = false;
			this.startTime = moment(date).format('YYYY-MM-DD')

		},
		changeTop(val){
			console.log(val)
			this.startTime = moment(val).format('YYYY-MM-DD')
			this.isShowTop = false
		},
		changeTime(val){
			console.log(val)
		},
		goEnd(){
			this.$toast('仅支持查询30天内数据');
			
		},
		changeBottom(val){
			this.endTime = moment(val).format('YYYY-MM-DD')
			this.isShowBottom = false
		},
		async submitFrom(){
			if(!this.$store.state.accessToken || (this.phone && this.phone.length != 0)){
				let phoneRg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
				if(!phoneRg.test(this.phone)){
					console.log('验证失败')
					this.$toast.fail('请输入正确的手机号');
					return false
				}
				console.log(this.code)
				let code = this.code + ''
				if(!this.code || code.length != 6){
					this.$toast.fail('请输入正确的验证码');
					return false
				}
				if(!this.startTime || this.startTime.length <3){
					this.$toast.fail('请选择开始日期');
					return false
				}

				if(!this.endTime || this.endTime.length <3){
					this.$toast.fail('请选择结束日期');
					return false
				}
			
				let endTimes = new Date(this.endTime).getTime()
				let startTimes = new Date(this.startTime).getTime()

				let timeNumber = parseInt((endTimes - startTimes)/86400000) || 0

				console.log(timeNumber)
				
				let result = await phoneLogin({
					phone: this.phone,
					code : this.code
				})
					if(result.data.statusCode == 200){
						console.log(result.data.data.refreshToken)
						window.sessionStorage.setItem('refreshToken', result.data.data.refreshToken)
						window.sessionStorage.setItem('accessToken', result.data.data.accessToken)
						this.$store.commit('setAccessToken',  result.data.data.accessToken)
						this.$store.commit('setRefreshToken',  result.data.data.refreshToken)
						this.getUserInfo(timeNumber)

					}else{
						this.$toast.fail(result.data.errors);
					}
			}else{


				if(!this.startTime || this.startTime.length <3){
					this.$toast.fail('请选择开始日期');
					return false
				}

				if(!this.endTime || this.endTime.length <3){
					this.$toast.fail('请选择结束日期');
					return false
				}

				let endTimes = new Date(this.endTime).getTime()
				let startTimes = new Date(this.startTime).getTime()

				let timeNumber = parseInt((endTimes - startTimes)/86400000) || 0
				this.getUserInfo(timeNumber)

					
			}


		},
		async getUserInfo(timeNumber){
			this.$router.push('/my?timeNumber=' + (timeNumber + 1))
		},
		async sendCode(){
			let phoneRg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
			if(!phoneRg.test(this.phone)){
				console.log('验证失败')
				this.$toast.fail('请输入正确的手机号');
				return false
			}
			this.isDisabled = true
			let result = await sendCode({
				phone: this.phone
			})
			console.log(result)
			if(result.status == 200){

				if(result.data.statusCode == 200){
					this.$toast.success('验证码发送成功');	

					let index = 90
					let This = this 
					clearInterval(this.isSendTime)
					this.isSendTime = setInterval(()=>{
						if(index > 1){
							index = index -1
							This.buttonText = `${index}秒`
						}else{
							This.buttonText = `重新获取验证码`
							clearInterval(This.isSendTime)
							This.isDisabled = false
						}
					}, 1000)
				}else if (result.data.statusCode == 400){
					if(result.data.errors.phone && result.data.errors.phone.length > 0){
						this.$toast.fail(result.data.errors.phone.join(','));
					}else{
						this.$toast.fail('请求发生错误');
					}
					this.isDisabled = false
				}
			}else{
				this.$toast.fail('网络错误,请检查网络');
				this.isDisabled = false
			}
		}
	},
	watch:{
		startTime:{
			handler(news){
				this.startTimeString = new Date(news)
			},
			deep: true,
			immediate: true
		}
	},
	components: {

	},
	beforeDestroy(){
		clearInterval(this.isSendTime)
	}
}
</script>

<style scoped>
.home{
  	width: 100%;
	height: 100%;
	background: #EDEDED;
}
.homeHeader{
	width: 100%;
	height: 220px;
	background: url('../assets/name.jpg') no-repeat;
	background-size: 100% 100%;
	position: relative;
}
.homeHeaderImage{
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: absolute;
	left: 10px;
	top: 40px;
}
.homeHeaderName{
	font-size: 50px;
	color: #fff;
	position: absolute;
	left: 100px;
	top: 50px;
}
.homeMain{
	padding: 25px;
	box-sizing: border-box;
}

.homeMainPhone{
	width: 100%;
	height: 80px;
	background: #fff;
	border-radius: 15px;
	outline: none;
	border: none;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 30px;
	margin-bottom: 20px;
}
.homeMainCodeBox{
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.homeMainCode{
	width: 55%;
	height: 80px;
	background: #fff;
	border-radius: 15px;
	outline: none;
	border: none;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 30px;
	margin-bottom: 15px;
}
.homeMainButton{
	width: 40%;
	height: 100%;
	background: #2884FF;
	border-radius: 15px;
	outline: none;
	border: none;
	font-size: 30px;
	color: #fff;
}
.homeMainButtonActive{
	background: #6EABFD;
}
.homeMainTime{
	width: 100%;
	height: auto;
	background: #fff;
	border-radius: 15px;
	padding: 10px 20px;
	box-sizing: border-box;
	margin-bottom: 80px;
}
.homeMainTimeItem{
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	box-sizing: border-box;
	line-height: 80px;
	font-size: 30px;
}
.homeMainTimeItemLeft{
	width: 50%;
	height: 100%;
	color: #4D4D4D;
}
.homeMainTimeItemRight{
	width: 50%;
	height: 100%;
	color: #B9B9B9;
	text-align: right;
}
.homeMainTimeItemRight span{
	color: #3893FE;
	margin-right: 15px;
}
.homeMainSearch{
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
}
.homeMainSearchButton{
	width: 350px;
	height: 100%;
	background: #2884FF;
	border-radius: 15px;
	outline: none;
	border: none;
	font-size: 30px;
	color: #fff;
}
</style>