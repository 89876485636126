import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	refreshToken: window.sessionStorage.getItem('refreshToken') || null,
	accessToken: window.sessionStorage.getItem('accessToken') || null,
	userInfo:{

	},
	tagName: {
		

	}
  },
  mutations: {
	  setAccessToken(state, num){
		state.accessToken = num
		if(num){
			window.sessionStorage.setItem('accessToken', num)
		}else{
			window.sessionStorage.removeItem('accessToken')
		}
		
	  },
	  setRefreshToken(state, num){
		state.refreshToken = num
		if(num){
			window.sessionStorage.setItem('refreshToken', num)
		}else{
			window.sessionStorage.removeItem('refreshToken')
		}
	  },
	  setUserInfo(state, num){
		  state.userInfo = num
	  },
	  setTagName(state,num){
		state.tagName = num


	  }
  },
  actions: {
  },
  modules: {
  }
})
