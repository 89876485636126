import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/my',
		name: 'My',
		component: () => import(/* webpackChunkName: "about" */ '../views/My.vue')
	},
	{
		path: '/default',
		name: 'Default',
		component: () => import(/* webpackChunkName: "about" */ '../views/Default.vue')
	}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
