import request from './request'

// 发送验证码
export const sendCode = async (data) =>{
    return await request({
        url: '/api/auth/send-code',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [
            function (data) {
               let ret = ''
               for (let it in data) {
                  ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
               }
               ret = ret.substring(0, ret.lastIndexOf('&'));
               return ret
            }
          ],
    })
};

// 手机和验证码登录
export const phoneLogin = async (data) =>{
    return await request({
        url: '/api/auth/phone-login',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [
            function (data) {
               let ret = ''
               for (let it in data) {
                  ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
               }
               ret = ret.substring(0, ret.lastIndexOf('&'));
               return ret
            }
          ],
    })
};

// 获取用户信息
export const info = async () =>{
    return await request({
        url: '/api/auth/info',
        method: 'get'
    })
};

// 查询用户报告
export const queryReport = async (data) =>{
    return await request({
        url: '/api/lis/query-report',
        method: 'get',
        params:{
            ...data
        }
    })
};

// 查询用户报告详情
export const queryReportItem = async (data) =>{
    return await request({
        url: '/api/lis/query-report-item',
        method: 'get',
        params:{
            ...data
        }
    })
};

// 查询用户报告详情
export const downloadReport = async (data) =>{
    return await request({
        url: '/api/lis/download-report',
        method: 'post',
        data:{
            ...data
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [
            function (data) {
               let ret = ''
               for (let it in data) {
                  ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
               }
               ret = ret.substring(0, ret.lastIndexOf('&'));
               return ret
            }
          ],
    })
};