import axios from 'axios'

import store from '../store/index'

import router from '../router'

// 创建axios实例
const service = axios.create({
    baseURL: '', //URL地址   环境变量文件 .env.development 'http://101.200.171.15:8777',//
    timeout: 15000,//超时
    // withCredentials: true,  //跨域时若要发生cookie,需要设置该选项
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        if (store.state.accessToken) {
            // 设置令牌请求头
            config.headers['Authorization'] = "Bearer " + store.state.accessToken
        }
        if (store.state.refreshToken) {
            // 设置令牌请求头
            config.headers['X-Authorization'] = "Bearer " + store.state.refreshToken
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(response=>{
    
    if(response.headers['access-token']){
        console.log('刷新Token了', response.headers['access-token'])
        store.commit('setAccessToken', response.headers['access-token'] )
        store.commit('setRefreshToken', response.headers['x-access-token'] )
    }
    // console.log(response.config.headers['access-token'])
    // console.log(response.config.headers['x-access-token'])
    // store.commit('setAccessToken', response.config.headers.Authorization)

    // if(response.data.code == -1){content-type: "application/json; charset=utf-8"
      
    // }
    return response;

},error =>{
    console.log(response.headers)
    // message.error('网络错误，请稍后重试');
   return Promise.reject(error)
})

export default service;
